@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 400;
  src: local("Bariol"), url("/assets/fonts/bariol/bariol_regular-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/bariol/bariol_regular-webfont.woff") format("woff"), url("/assets/fonts/bariol/bariol_regular-webfont.ttf") format("truetype"), url("/assets/fonts/bariol/bariol_regular-webfont.svg#AlegreyaSans") format("svg");
}

@font-face {
  font-family: 'Bariol';
  font-style: italic;
  font-weight: 400;
  src: local("Bariol"), url("/assets/fonts/bariol/bariol_regular_italic-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/bariol/bariol_regular_italic-webfont.woff") format("woff"), url("/assets/fonts/bariol/bariol_regular_italic-webfont.ttf") format("truetype"), url("/assets/fonts/bariol/bariol_regular_italic-webfont.svg#AlegreyaSans") format("svg");
}

@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 700;
  src: local("Bariol"), url("/assets/fonts/bariol/bariol_bold-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/bariol/bariol_bold-webfont.woff") format("woff"), url("/assets/fonts/bariol/bariol_bold-webfont.ttf") format("truetype"), url("/assets/fonts/bariol/bariol_bold-webfont.svg#AlegreyaSans") format("svg");
}

/**
 * Google Fonts
 *
 */
/** End Extensions */
/**
 * Global Variables
 *
 */
/** End Global Variables */
/**
 * App-wide Styles
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Arial, sans-serif;
  color: #555;
}

body {
  overflow-x: hidden;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
}

img {
  display: inline-block;
  height: auto;
  width: auto;
  max-width: 100%;
}

p {
  font-size: 16px;
  font-size: 1.6rem;
}

a {
  text-decoration: none;
  color: #FF9900;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bariol", Arial, sans-serif;
  margin: 0 0 10px;
}

.button, .site-banner__button, .usage__menu-link {
  border-radius: 5px;
  text-align: center;
  padding: 20px 0;
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Bariol", Arial, sans-serif;
}

.limiter {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 20px;
}

/* inject:scss */
.site-banner {
  background-image: url(../assets/images/header.png), url(../assets/images/gradient.jpg);
  background-size: 500px auto, auto 100%;
  background-repeat: no-repeat, repeat-x;
  background-position: top center;
  overflow: hidden;
  position: relative;
  padding: 20px 0 40px;
  margin-bottom: 40px;
}

@media screen and (min-width: 800px) {
  .site-banner {
    padding: 70px;
    background-size: 100%, auto 100%;
  }
  .site-banner:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -47px;
    left: 0;
    width: 100%;
    height: 94px;
    background: #fff;
    transform: skew(0deg, -4deg);
    -ms-transform: skew(0deg, -8deg);
    -webkit-transform: skew(0deg, -4deg);
    z-index: 2;
  }
}

.site-banner__title {
  color: #192F44;
  font-size: 60px;
  font-size: 6rem;
  line-height: 1;
  font-style: italic;
  max-width: 260px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 800px) {
  .site-banner__title {
    margin-bottom: 40px;
  }
}

.site-banner__subtitle {
  font-size: 18px;
  font-size: 1.8rem;
  max-width: 400px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 800px) {
  .site-banner__subtitle {
    margin-bottom: 40px;
  }
}

.site-banner__logo {
  max-width: 80%;
  display: block;
  z-index: 5;
  margin: 0 auto 30px;
  position: relative;
}

@media screen and (min-width: 450px) {
  .site-banner__logo {
    position: absolute;
    max-width: 25%;
    right: 8%;
    top: 20px;
    margin: 0;
  }
}

@media screen and (min-width: 800px) {
  .site-banner__logo {
    max-width: 30%;
  }
}

@media screen and (min-width: 1136px) {
  .site-banner__logo {
    max-width: 355px;
    bottom: 0px;
    top: auto;
    right: 15%;
  }
}

.site-banner__alga {
  z-index: 1;
  position: absolute;
  bottom: -20px;
  left: 45%;
  max-width: 140px;
  display: none;
}

@media screen and (min-width: 800px) {
  .site-banner__alga {
    bottom: 40px;
    display: block;
  }
}

.site-banner__fish {
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 4;
  max-width: 170px;
}

.site-banner__action-links {
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 450px) {
  .site-banner__action-links {
    display: flex;
    justify-content: space-between;
    max-width: 470px;
  }
}

@media screen and (min-width: 800px) {
  .site-banner__link {
    max-width: 48%;
  }
}

.site-banner__button {
  background-color: #E35A42;
}

.site-banner__button:hover {
  background-color: #d33a1f;
}

.site-banner__button-secondary {
  background-color: #9F2165;
}

.site-banner__button-secondary:hover {
  background-color: #75184a;
}

.site-banner__caption {
  font-size: 12px;
  font-size: 1.2rem;
}

.general-info {
  margin-bottom: 50px;
}

@media screen and (min-width: 800px) {
  .general-info .limiter {
    display: flex;
    justify-content: space-between;
  }
}

.general-info__column {
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .general-info__column {
    width: 48%;
  }
}

@media screen and (min-width: 800px) {
  .general-info__column p {
    margin-bottom: 30px;
  }
}

.general-info__column pre {
  background: #F2F2F2;
  padding: 20px;
  color: #000;
  border-radius: 10px;
  font-size: 18px;
  font-size: 1.8rem;
  white-space: pre-line;
}

@media screen and (min-width: 800px) {
  .general-info__column pre {
    margin-bottom: 30px;
  }
}

.general-info__title {
  font-size: 30px;
  font-size: 3rem;
  color: #192F44;
}

.emphasis__underline {
  font-style: italic;
  font-family: "Bariol", Arial, sans-serif;
  font-size: 35px;
  font-size: 3.5rem;
  color: #192F44;
  text-align: center;
}

.emphasis__underline strong {
  display: inline-block;
  border-bottom: solid 5px #E35A42;
}

.emphasis__why {
  color: #9F2165;
  font-weight: bold;
}

.general-info__wave {
  float: right;
  max-width: 153px;
}

.site-secondary {
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  .site-secondary .limiter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 800px) {
  .site-secondary__column {
    width: 26%;
  }
  .site-secondary__column:first-child {
    width: 42%;
  }
}

.site-secondary__title {
  font-size: 25px;
  font-size: 2.5rem;
  color: #192F44;
}

.usage {
  background: #192F44;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  .usage {
    padding: 40px 0;
    margin-bottom: 50px;
  }
}

.usage p {
  color: #fff;
}

@media screen and (min-width: 800px) {
  .usage .limiter {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 800px) {
  .usage__intro {
    width: 48%;
  }
}

.usage__intro-title {
  color: #35BBA0;
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  .usage__menu-container {
    width: 48%;
  }
}

.usage__nav-caption p {
  color: #35BBA0;
}

@media screen and (min-width: 800px) {
  .usage__menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.usage__menu-link {
  padding: 12px 15px;
  background-color: #E35A42;
  text-align: left;
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 800px) {
  .usage__menu-link {
    width: 49%;
    display: block;
  }
  .usage__menu-link:hover {
    background-color: #d33a1f;
  }
  .usage__menu-link:nth-child(1), .usage__menu-link:nth-child(2) {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 800px) {
  .site-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
  }
}

.site-footer__column {
  padding: 20px;
  color: #fff;
}

@media screen and (min-width: 800px) {
  .site-footer__column {
    width: 50%;
    padding: 40px;
  }
}

.site-footer__column-credits {
  background: #2A455E;
}

.site-footer__column-company {
  background: #192F44;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.site-footer__title {
  color: #35BBA0;
  font-size: 25px;
  font-size: 2.5rem;
}

.site-footer__built-by {
  color: #35BBA0;
  text-align: center;
  font-family: "Bariol", Arial, sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
}

.site-footer__built-by a {
  text-decoration: underline;
  font-style: normal;
  color: #35BBA0;
}

.site-footer__waves {
  max-width: 152px;
}

.closure_title {
  padding: 40px 0;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #35BBA0;
  font-size: 22px;
  font-size: 2.2rem;
  font-family: "Bariol", Arial, sans-serif;
  vertical-align: center;
}

.closure_title:after {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../assets/images/heart.png) top left no-repeat;
  background-size: 18px 16px;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

/* endinject */
