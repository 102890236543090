// footer

.site-footer {
  @media screen and (min-width: $medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
  }
}

.site-footer__column {
  padding: 20px;
  color: #fff;
  @media screen and (min-width: $medium) {
    width: 50%;
    padding: 40px;
  }
}
.site-footer__column-credits {
  background: #2A455E;
}
.site-footer__column-company {
  background: #192F44;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.site-footer__title {
  color: $managreen;
  @include font-size(2.5)
}
.site-footer__content {}
.site-footer__built-by {
  color: $managreen;
  text-align: center;
  font-family: $heading-font;
  @include font-size(2.2)
  a {
    text-decoration: underline;
    font-style: normal;
    color: $managreen
  }
}

.site-footer__waves {
  max-width: 152px;
}

.closure_title {
  padding: 40px 0;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: $managreen;
  @include font-size(2.2);
  font-family: $heading-font;
  vertical-align: center;
  &:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/images/heart.png) top left no-repeat;
    background-size: 18px 16px;
    position: relative;
    top: 2px;
    margin-left: 10px;
  }
}
