// banner

.site-banner {
  background-image: url(../assets/images/header.png), url(../assets/images/gradient.jpg);
  background-size: 500px auto, auto 100%;
  background-repeat: no-repeat, repeat-x;
  background-position: top center;
  overflow: hidden;
  position: relative;
  padding: 20px 0 40px;
  margin-bottom: 40px;
  @media screen and (min-width: $medium) {
    padding: 70px;
    background-size: 100%, auto 100%;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -47px;
      left: 0;
      width: 100%;
      height: 94px;
      background: #fff;
      transform:skew(0deg,-4deg);
      -ms-transform:skew(0deg,-8deg);
      -webkit-transform:skew(0deg,-4deg);
      z-index: 2;
    }
  }
}

.site-banner__title {
  color: $heading-darkest;
  @include font-size(6.0);
  line-height: 1;
  font-style: italic;
  max-width: 260px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  @media screen and (min-width: $medium) {
    margin-bottom: 40px;
  }
}
.site-banner__subtitle {
  @include font-size(1.8);
  max-width: 400px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  @media screen and (min-width: $medium) {
    margin-bottom: 40px;
  }
}

.site-banner__logo {
  max-width: 80%;
  display: block;
  z-index: 5;
  margin: 0 auto 30px;
  position: relative;
  @media screen and (min-width: $small) {
    position: absolute;
    max-width: 25%;
    right: 8%;
    top: 20px;
    margin: 0;
  }
  @media screen and (min-width: $medium) {
    max-width: 30%;
  }
  @media screen and (min-width: 1136px) {
    max-width: 355px;
    bottom: 0px;
    top: auto;
    right: 15%;
  }
}

.site-banner__alga {
  z-index: 1;
  position: absolute;
  bottom: -20px;
  left: 45%;
  max-width: 140px;
  display: none;
  @media screen and (min-width: $medium) {
    bottom: 40px;
    display: block;
  }
}
.site-banner__fish {
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 4;
  max-width: 170px;
}

.site-banner__action-links {
  position: relative;
  z-index: 10;
  @media screen and (min-width: $small) {
    display: flex;
    justify-content: space-between;
    max-width: 470px;
  }
}
.site-banner__link {
  @media screen and (min-width: $medium) {
    max-width: 48%;
  }
}
.site-banner__button {
  @extend .button;
  background-color: $button-primary-bg;
  &:hover {
    background-color: darken($button-primary-bg, 10);
  }
}
.site-banner__button-secondary {
  background-color: $button-secondary-bg;
  &:hover {
    background-color: darken($button-secondary-bg, 10);
  }
}
.site-banner__caption {
  @include font-size(1.2);
}
