// general info

.general-info {
  margin-bottom: 50px;
  .limiter {
    @media screen and (min-width: $medium) {
      display: flex;
      justify-content: space-between;
    }
  }

}

.general-info__column {
  overflow: hidden;
  @media screen and (min-width: $medium) {
    width: 48%;
  }
  p {
    @media screen and (min-width: $medium) {
      margin-bottom: 30px;
    }
  }
  pre {
    background: $light-gray;
    padding: 20px;
    color: #000;
    border-radius: 10px;
    @include font-size(1.8);
    white-space: pre-line;
    @media screen and (min-width: $medium) {
      margin-bottom: 30px;
    }
  }
}

.general-info__title {
  @include font-size(3.0);
  color: $heading-darkest;
}

.emphasis__underline {
  font-style: italic;
  font-family: $heading-font;
  @include font-size(3.5);
  color: $heading-darkest;
  text-align: center;
  strong {
    display: inline-block;
    border-bottom: solid 5px $button-primary-bg;
  }
}

.emphasis__why {
  color: #9F2165;
  font-weight: bold;
}

.general-info__wave {
  float: right;
  max-width: 153px;
}
