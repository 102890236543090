// usage

.usage {
  background: $heading-darkest;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: $medium) {
    padding: 40px 0;
    margin-bottom: 50px;
  }
  p {
    color: #fff;
  }
  .limiter {
    @media screen and (min-width: $medium) {
      display: flex;
      justify-content: space-between;
    }
  }
}
.usage__intro {
  @media screen and (min-width: $medium) {
    width: 48%;
  }
}
.usage__intro-title {
  color: $managreen;
  @include font-size(3);
  margin-bottom: 20px;
}
.usage__menu-container {
  @media screen and (min-width: $medium) {
    width: 48%;
  }
}
.usage__nav-caption {
  p {
    color: $managreen;
  }
}
.usage__menu {
  @media screen and (min-width: $medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.usage__menu-link {
  @extend .button;
  padding: 12px 15px;
  background-color: $button-primary-bg;
  text-align: left;
  margin-bottom: 10px;
  @include font-size(1.6);
  @media screen and (min-width: $medium) {
    &:hover {
      background-color: darken($button-primary-bg, 10);
    }
    width: 49%;
    display: block;
    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 10px;
    }
  }
}
